"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geologicaBold = void 0;
exports.geologicaBold = {
    familyName: "Geologica",
    weight: "700",
    style: "normal",
    ttfName: "GeologicaThinRoman-Bold",
    ttfFile: "geologica-bold.ttf",
    woff2File: "geologica-bold.woff2",
};
