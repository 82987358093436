"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geologicaLight = void 0;
exports.geologicaLight = {
    familyName: "Geologica",
    weight: "300",
    style: "normal",
    ttfName: "GeologicaThinRoman-Light",
    ttfFile: "geologica-light.ttf",
    woff2File: "geologica-light.woff2",
};
