"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geologicaRegular = void 0;
exports.geologicaRegular = {
    familyName: "Geologica",
    weight: "400",
    style: "normal",
    ttfName: "GeologicaThinRoman-Regular",
    ttfFile: "geologica-regular.ttf",
    woff2File: "geologica-regular.woff2",
};
