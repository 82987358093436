"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.geologicaUppercase = void 0;
const geologica_bold_1 = require("../fonts/geologica-bold");
const geologica_regular_1 = require("../fonts/geologica-regular");
const geologica_light_1 = require("../fonts/geologica-light");
exports.geologicaUppercase = {
    headingXLarge: {
        font: geologica_bold_1.geologicaBold,
        fontSize: 29,
        lineHeight: 38,
        letterSpacing: undefined,
        textTransform: "uppercase",
    },
    headingLarge: {
        font: geologica_bold_1.geologicaBold,
        fontSize: 23,
        lineHeight: 32,
        letterSpacing: undefined,
        textTransform: "uppercase",
    },
    headingMedium: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 17,
        lineHeight: 21,
        letterSpacing: undefined,
        textTransform: "uppercase",
    },
    headingSmall: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: 1.8,
        textTransform: "uppercase",
    },
    body: {
        font: geologica_light_1.geologicaLight,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: geologica_light_1.geologicaLight,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: geologica_regular_1.geologicaRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
